import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Row,
    Table,
    Dropdown,
    Form
} from "react-bootstrap";
import config from "constants/config"
import toast from "react-hot-toast"

import httpClient from 'http-common'

function ListOfVoice(props) {
    const { show, onHide, itemData } = props
    const [voiceDetails, setVoiceDetails] = useState([]);
    const [voiceAllDetails, setVoiceAllDetails] = useState([]);

    const [selectedId, setSelectedId] = useState();
    const [searchValue, setSearchValue] = useState('');


    const closeModal = (value) => {
        onHide(value);
    }


    const deleteVoiceId = async (id) => {
        const token = localStorage.getItem('token')
        try {
            const response = await httpClient.delete(config.API_URL + `/admin/dashboard/companies/${itemData._id}/voices/${id}`)
            const { data, status } = response.data;
            if (status == 200 || status === 201) {
                toast.success("Delete successfully")
                closeModal(1);
            } else {
                closeModal(0);
                toast.error("Something wrong!. Try again after sometime")
            }
        } catch (error) {
            const responseData = error.response;
            closeModal(0);
            if (responseData.status === 409) {
                toast.error("Email already present in system");
            } else {
                toast.error("Something wrong! Try gain later")
            }
        }

    }
    const findObjectsByKeyLike = (arr, key, substring) => {
        const lowerCaseSubstring = substring.toLowerCase();
        return arr.filter(item => item[key].toLowerCase().includes(lowerCaseSubstring));
      }

    useEffect(() => {
        setVoiceDetails(itemData.dubbing_voice)
        setVoiceAllDetails(itemData.dubbing_voice)

    }, [voiceDetails]);

    return (

        <Modal {...props} size="lg" backdrop="static">
            <Modal.Header>
                <Modal.Title>List Of Company Voices</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row style={{ padding: "2rem" }}>

                    <Form.Control type="text" placeholder="Search by name" value={searchValue} onChange={(v) => {
                        setSearchValue(v.target.value)
                        setTimeout(() => {
                            const valueData = findObjectsByKeyLike(voiceAllDetails, 'voice_name', v.target.value);
                            setVoiceDetails(valueData)
                        }, 1000);

                    }} />

                </Row>
                <Row>
                    {voiceDetails.length > 0 && <Table className="table-hover table-striped">
                        <thead>
                            <tr>
                                <th className="border-0">Voice name</th>
                                <th className="border-0">Voice Id</th>
                                <th className="border-0">Source</th>
                                <th className="border-0">Gender</th>
                                <th className="border-0">Accent</th>
                                <th className="border-0">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {voiceDetails.length > 0 && voiceDetails.map((v, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{v.voice_name}</td>
                                        <td>{v.voice_id}</td>
                                        <td>{v.source}</td>
                                        <td>{v.gender}</td>
                                        <td>{v.accent}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                    Action
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={(vt) => {
                                                        setSelectedId(v._id);
                                                        deleteVoiceId(v._id);

                                                    }}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>}
                    {voiceDetails.length <= 0 && <h5>No Data Found</h5>}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>

    );
}

export default ListOfVoice;