import React, { useState , useEffect} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import httpClient from '../http-common'
import axios from "axios";
import config from "../constants/config"
import toast from "react-hot-toast"

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { useHistory } from 'react-router-dom';

function Login() {
  let history = useHistory()
  const [isOtp, setIsOtp] = useState(false);
  const [email, setEmail] = useState(null);
  const [otp, setOtp] = useState(null);
  const [isOtpBlank, setIsOtpBlank] = useState(false);
  const [isResend, setIsResend] = useState(false);


  const submitOtp = async () => {
    if(otp != "" && otp != null){
      
      setIsOtpBlank(false)
      const otpValid = {
        "email": email || localStorage.getItem("email"),
        "otp": otp
      }
      try {
        const loginDetails = await axios.post(config.API_URL +'/admin/otp/validate', otpValid);
        const {data, status} = loginDetails;
        if(status === 200){
          localStorage.setItem("token", data.data.token);
          history.push('/admin/dashboard')
        }else {
          toast.error("Something wrong!. Try again after sometime")
  
        }
      } catch (error) {
        console.log("error ::: ", error)
        toast.error(error.response.data.message)
      }
    }else {
      setIsOtpBlank(true);
    }
  }
  const disabledText = () => {
    setTimeout(() => {
      setIsResend(true)
    }, 60000);

  }
  const login = async (values) => {
    try {
      const loginDetails = await axios.post(config.API_URL + '/admin/login/otp', values);
      const { data, status } = loginDetails;
      if (status === 200) {
        localStorage.setItem("email", values.email)
        setIsOtp(true);
        setEmail(values.email);
        disabledText();
        // history.push('/admin/dashboard')
      } else {
        toast.error("Something wrong!. Try again after sometime")

      }
    } catch (error) {
      console.log("error ::: ", error)
      toast.error(error.response.data.message)
    }
  }

  const resendOpt = async (values) => {
    try {
      const email = localStorage.getItem("email")
      const loginDetails = await axios.post(config.API_URL + '/admin/otp/resend', {email});
      const { data, status } = loginDetails;
      if (status === 200) {
        setIsOtp(true);
        setIsResend(false);
        disabledText();
        toast.success("Otp Send successfully. Please check you email")
        // history.push('/admin/dashboard')
      } else {
        toast.error("Something wrong!. Try again after sometime")

      }
    } catch (error) {
      console.log("error ::: ", error)
      toast.error(error.response.data.message)
    }
  }
  useEffect(() => {
    // disabledText();
  }, []);


  return (
    <>

      <Container className="">
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mt-5">
              <Card.Header>
                <Card.Title as="h4">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                {!isOtp && <Formik
                  initialValues={{ email: "" }}
                  onSubmit={async (values, { setSubmitting }) => {
                    console.log("Logging in", values);
                    login(values)
                   
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email()
                      .required("Required")
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.email && touched.email && "error"}
                              ></Form.Control>
                              {errors.email && touched.email && (
                                <div className="input-feedback">{errors.email}</div>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn-fill pull-right mt-4"
                          type="submit"
                          variant="info"
                        >
                          Login In
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    );
                  }}
                </Formik>}

                {isOtp &&
                  <Form>
                    <Row>
                      <Col >
                        <Form.Group>
                          <label>OTP</label>
                          <Form.Control
                            placeholder="Otp"
                            type="password"
                            name="password"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value)
                            }}
                          ></Form.Control>
                          {isOtpBlank &&
                            <div className="input-feedback">Otp required!</div>
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row  >
                      {isResend && <a className="resend-enabled" onClick={() => resendOpt()} >Resend OTP</a>}
                      {!isResend && <a className='resend-disabled' >Resend OTP</a>}
                    </Row>
                    <Button
                      className="btn-fill pull-right mt-4 mr-4"
                      variant="Success"
                      onClick={() => setIsOtp(false)}
                    >
                      Back
                    </Button>

                    <Button style={{marginLeft: "10px"}}
                      className="btn-fill pull-right mt-4"
                      variant="info"
                      onClick={submitOtp}
                    >
                      Validate
                    </Button>
                    <div className="clearfix"></div>
                  </Form>}



              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export default Login;