import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
// import httpClient from 'http-common'
import axios from "axios";
import config from "constants/config"
import toast from "react-hot-toast"
import httpClient from 'http-common'
import * as _ from 'lodash'

// react-bootstrap components
import {
    Modal,
    Button,
    Form,
    Row,
    Col,
    Table
} from "react-bootstrap";



function AddTranslator(props) {
    const { itemData } = props
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState("");
    const [contactNum, setContactNum] = useState("");
    const [pricing, setPricing] = useState("")
    const [targetLang, setTargetLang] = useState([])
    const [sourceLang, setSourceLang] = useState([])
    const [constSourceAssent, setConstSourceAssent] = useState([])
    const [constTargetAssent, setConstTargetAssent] = useState([])
    const [showSourceAssent, setShowSourceAssent] = useState(true)
    const [sowTargetAssent, setShowTargetAssent] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [company, setCompany] = useState([]);
    const [allCompany, setAllCompany] = useState([]);

    const [search, setSearch] = useState('');








    const closeModal = (value) => {
        const { onHide } = props
        onHide(value);
    }
    const loadContLang = async (index, type, number) => {
        if (type != 1) {
            await setShowSourceAssent(false);
            const sourceValue = constSourceAssent;
            sourceValue[index].isSelected = number;
            await setConstSourceAssent(sourceValue);
            await setShowSourceAssent(true);
        } else {
            await setShowTargetAssent(false);
            const targetValue = constTargetAssent;
            targetValue[index].isSelected = number;
            await setConstTargetAssent(targetValue);
            await setShowTargetAssent(true);
        }

    }

    const getAllCompany = async () => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.get(config.API_URL + "/admin/dashboard/companies")
            setCompany(getCompany.data.data)
            setAllCompany(getCompany.data.data)

        } catch (error) {
            console.log(error)
        }

    }

    const editRecord = async () => {
        if (email === "") {
            setErrorMessage("Email is required!");
            return;
        } else if (targetLang.length <= 0) {
            setErrorMessage("Select at-lest once target lang");
            return;
        } else if (sourceLang.length <= 0) {
            setErrorMessage("Select at-lest once source lang");
            return;
        } else if (contactNum === "") {
            setErrorMessage("Contact number is required!");
            return;
        } else if (pricing === "") {
            setErrorMessage("Pricing is required!");
            return;
        } else {
            const payload = {
                translator: email,
                targetAccent: _.map(targetLang, 'name'),
                sourceAccent: _.map(sourceLang, 'name'),
                contactNum,
                pricing
            };

            try {
                const token = localStorage.getItem('token');
                const headerData = {
                    'Authorization': `Bearer ${token}`
                }
                const response = await httpClient.patch(config.API_URL + '/admin/dashboard/translators/' + itemData._id, payload);
                const { data, status } = response.data;
                if (status === 200 || status === 201) {
                    toast.success("Translator Edit successfully")
                    closeModal(1);
                } else {
                    closeModal(0);
                    toast.error("Something wrong!. Try again after sometime")
                }
            } catch (error) {
                const responseData = error.response;
                closeModal(0);
                if (responseData.status === 409) {
                    toast.error(responseData);
                } else {
                    toast.error("Something wrong! Try gain later")
                }
            }
        }
    }

    const addRecord = async () => {
        if (email === "") {
            setErrorMessage("Email is required!");
            return;
        } else if (targetLang.length <= 0) {
            setErrorMessage("Select at-lest once target lang");
            return;
        } else if (sourceLang.length <= 0) {
            setErrorMessage("Select at-lest once source lang");
            return;
        } else if (contactNum === "") {
            setErrorMessage("Contact number is required!");
            return;
        } else if (pricing === "") {
            setErrorMessage("Pricing is required!");
            return;
        } else {
            const payload = {
                translator: email,
                targetAccent: _.map(targetLang, 'name'),
                sourceAccent: _.map(sourceLang, 'name'),
                contactNum,
                pricing
            };

            try {
                const token = localStorage.getItem('token');
                const headerData = {
                    'Authorization': `Bearer ${token}`
                }
                const response = await httpClient.post(config.API_URL + '/admin/dashboard/translator', payload);
                const { data, status } = response.data;
                if (status === 200 || status === 201) {
                    toast.success("Translator added successfully")
                    closeModal(1);
                } else {
                    closeModal(0);
                    toast.error("Something wrong!. Try again after sometime")
                }
            } catch (error) {
                const responseData = error.response;
                closeModal(0);
                if (responseData.status === 409) {
                    toast.error(responseData);
                } else {
                    toast.error("Something wrong! Try gain later")
                }
            }
        }

    }

    const findObjectsByKeyLike = (arr, key, substring) => {
        const lowerCaseSubstring = substring.toLowerCase();
        return arr.filter(item => item[key].toLowerCase().includes(lowerCaseSubstring));
      }



    const loadItemValue = async (tgrLang, srLang) => {

        await setEmail(itemData.translator);
        await setContactNum(itemData.contactNum)
        await setPricing(itemData.pricing);
        const trgLang = tgrLang;
        const tgLang = [];
        _.each(itemData.targetAccent, (v) => {
            const findData = _.find(config.TARGET_LANGUAGE_MAPS, (vt) => v === vt.name);
            const findIndex = _.findIndex(trgLang, (vm) => v === vm.name);
            if (findIndex > -1) {
                trgLang[findIndex].isSelected = 1;
            } else {
                trgLang[findIndex].isSelected = 0
            }
            if (findData) {
                tgLang.push(findData)
            }
        });

        const sourLang = srLang;
        const surLang = [];

        _.each(itemData.sourceAccent, (v) => {
            const findData = _.find(config.SOURCE_LANGUAGE_MAPS, (vt) => v === vt.name);
            const findIndex = _.findIndex(sourLang, (vm) => v === vm.name);

            if (findIndex > -1) {
                sourLang[findIndex].isSelected = 1;
            } else {
                sourLang[findIndex].isSelected = 0;
            }

            if (findData) {
                surLang.push(findData)
            }
        });




        setTimeout(() => {

            setConstSourceAssent([])
            setConstSourceAssent(sourLang);
            setSourceLang(surLang);
            setConstTargetAssent([])
            setConstTargetAssent(trgLang);
            setTargetLang(tgLang);
        }, 1000);





        // const sourLang =_.map(config.SOURCE_LANGUAGE_MAPS, (v) => {return { ...v, isSelected: 0 }});;


    }
    const resetFunction = async () => {
        const trgLang = _.map(config.TARGET_LANGUAGE_MAPS, (v) => { return { ...v, isSelected: 0 } });
        await setConstSourceAssent(trgLang);
        const sourLang = _.map(config.SOURCE_LANGUAGE_MAPS, (v) => { return { ...v, isSelected: 0 } });;
        await setConstTargetAssent(sourLang);

        if (itemData != null && itemData != undefined) {
            setSourceLang([]);
            setTargetLang([]);
            loadItemValue(trgLang, sourLang);

        } else {
            await setConstSourceAssent([])
            await setConstTargetAssent([])
            await setShowSourceAssent(false)
            await setShowTargetAssent(false)
            await setSourceLang([]);
            await setTargetLang([]);
            await setEmail("");
            await setConstSourceAssent(sourLang)
            await setConstTargetAssent(trgLang)
            await setContactNum("");
            await setPricing("");
            await setShowSourceAssent(true)
            await setShowTargetAssent(true)
        }

    }
    useEffect(() => {
        getAllCompany();
        console.log(itemData);


    }, []);


    return (
        <Modal {...props} size="lg" onShow={resetFunction} backdrop="static" aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header>
                <Modal.Title>Add Translator</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {itemData === undefined && <Row>
                    <Col>
                    <Form.Control type="text" placeholder="Search by name" value={search} onChange={(v) => {
                        setSearch(v.target.value)
                        setTimeout(() => {
                            const valueData = findObjectsByKeyLike(allCompany, 'name', v.target.value);
                            setCompany(valueData)
                            
                        }, 1000);
                    }} />
                    </Col>
                </Row>}
                {itemData === undefined && <Row style={{ height: "200px", overflow: "auto",  marginTop: "10px" }}>
                    <Table className="table-hover table-striped">
                        <thead>
                            <tr>
                                <th className="border-0">Name</th>
                                <th className="border-0">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {company.length > 0 && company.map((v, index) => {
                                return <tr key={index}>
                                    <td>
                                        <Form.Check
                                            onClick={
                                                (vs) => {
                                                    setEmail(v._id)
                                                }
                                            }
                                            name='group1'
                                            type="radio"
                                            id={`default-checkbox`}
                                        />
                                    </td>
                                    <td>{v.name}</td>
                                    <td>{v.email}</td>
                                </tr>;
                            })}

                        </tbody>

                    </Table>
                </Row>}

                <Row>
                    <div className='target_accent'>
                        <h5 >Target Accent</h5>
                        <ul >
                            {showSourceAssent && constTargetAssent.map((v, index) => {
                                return <li> <Button
                                    onClick={() => {

                                        const targetLangValue = targetLang;
                                        const findIndex = _.findIndex(targetLangValue, (vt) => {
                                            return vt.name === v.name
                                        })
                                        if (findIndex > -1) {
                                            targetLangValue.splice(findIndex, 1);
                                            loadContLang(index, 1, 0)
                                        } else {
                                            targetLangValue.push(v);
                                            loadContLang(index, 1, 1)
                                        }

                                        setTargetLang(targetLangValue)
                                    }}
                                    variant={v.isSelected === 1 ? 'success' : 'Primary'}> {v.value}</Button>{' '}</li>
                            })}
                        </ul>
                    </div>
                </Row>

                <Row>
                    <div className='target_accent'>
                        <h5 >Source Accent</h5>
                        <ul >
                            {sowTargetAssent && constSourceAssent.map((v, index) => {
                                return <li> <Button
                                    onClick={() => {
                                        const targetLangValue = sourceLang;
                                        const findIndex = _.findIndex(targetLangValue, (vt) => {
                                            return vt.name === v.name
                                        })
                                        if (findIndex > -1) {
                                            targetLangValue.splice(findIndex, 1);
                                            loadContLang(index, 2, 0)
                                        } else {
                                            targetLangValue.push(v);
                                            loadContLang(index, 2, 1)
                                        }
                                        setSourceLang(targetLangValue)
                                    }}
                                    variant={v.isSelected === 1 ? 'success' : 'Primary'}>{v.value} </Button>{' '}</li>
                            })}
                        </ul>
                    </div>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="text" value={contactNum} onChange={(v) => setContactNum(v.target.value)} placeholder="XXXXXXXXXX" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" value={pricing} onChange={(v) => setPricing(v.target.value)} placeholder="100" />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    {errorMessage != "" && <span className='error_text_color'>{errorMessage}</span>}
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                {itemData != null && <Button variant="primary" type="submit" onClick={editRecord} >
                    Edit
                </Button>}

                { itemData === undefined && <Button variant="primary" type="submit" onClick={addRecord} >
                    Add
                </Button>}
            </Modal.Footer>


        </Modal>
    );
}

export default AddTranslator;