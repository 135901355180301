import * as _ from 'lodash'

export const findLanguage = (langArray, langKey, langValue, value) => {
	const findLang = _.find(langArray, (vt) => vt[langKey] === value);
	return findLang[langValue];
}

export const filterGcsUploadFileName = (fullName, maxLength) => {
  const extension = fullName.split('.').pop()
  const parsedFileName = fullName
    .replace(/\.[^.]+$/, '') // Remove extension
    .replace(/[^\w\s]/gi, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphen
  const limitedName = `${parsedFileName}_${Date.now()}`.slice(0, maxLength) // extracting first 15 characters
  return `${limitedName}.${extension}` // join the final name with extension
}