import AssignTranslatorOrQaModal from 'components/Shared/AssignedTranslatorOrQA';
import config from "constants/config";
import httpClient from 'http-common';
import * as _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

// react-bootstrap components
import * as moment from "moment";
import {
    Button, Card, Col, Container, Dropdown, Form, Row, Table
} from "react-bootstrap";

function Videos() {
    const [videos, setVideos] = useState([]);
    const [filterValue, setFilterValue] = useState('NOT_STARTED');
    const [searchValue, setSearchValue] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);

    const [type, setType] = useState(1);
    const [selectItem, setSelectItem] = useState('');
    const [targetLang, setTargetLang] = useState(config.LANGUAGES_ASSENTS);
    const [sourceLang, setSourceLang] = useState(config.LANGUAGES_ASSENTS_SOURCE)




    const getAllVideos = async () => {
        try {
            // const getCompany = await httpClient.patch(config.API_URL + "/dashboard/dubbed/videos")

            const payload = {
                limit: 20, page: 1,
            }
            const targetLangValue = []
            _.each(targetLang, (v) => {
                if (v.selected) {
                    targetLangValue.push(v.value)
                }
            })
            const sourceLangValue = []
            _.each(sourceLang, (v) => {
                if (v.selected) {
                    sourceLangValue.push(v.value)
                }
            });
            if (targetLangValue.length > 0) {
                payload.filterBy = {
                    ...payload.filterBy,
                    'targetLanguage': targetLangValue
                }
            }
            if (sourceLangValue.length > 0) {
                payload.filterBy = {
                    ...payload.filterBy,
                    'sourceLanguage': sourceLangValue
                }
            }
            if (filterValue !== "") {
                payload.filterBy = {
                    ...payload.filterBy,
                    'reviewStatus': filterValue
                }
            }

            if (searchValue !== "") {
                payload.searchBy = {
                    title: searchValue
                }
            }
            const getCompany = await httpClient.patch(config.API_URL + "/admin/dashboard/dubbed/videos", payload)

            setVideos(getCompany.data.data.videos);
            console.log("videos :::::: ", videos)

        } catch (error) {
            console.log(error)
        }

    }


    const assignedTranslator = async (value, tatDate) => {
        try {
            console.log()
            const typeData = (type === 1) ? 'translator' : 'QA';
            const getCompany = await httpClient.post(config.API_URL + "/admin/dashboard/dubbed/video/assign/" + typeData, {
                userId: value,
                videoId: selectItem._id,
                tatDate
            })

            getAllVideos('', 0)
            setSelectItem('')
            toast.success("Assign successfully");
        } catch (error) {
            toast.error("Something is wrong! Please try again sometime later.");
        }
    }

    const checkBoxHanlde = (event, index) => {
        setTargetLang([])
        if (event.target.checked) {
            const trgLang = targetLang;
            trgLang[index].selected = true
            setTimeout(() => {
                setTargetLang(trgLang)

            }, 100);

        } else {
            const trgLang = targetLang;
            trgLang[index].selected = false
            setTimeout(() => {
                setTargetLang(trgLang)

            }, 100);
        }

    }
    const secondsToMinutesAndSeconds = (seconds) => {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = Math.round(seconds % 60);

        return `${minutes}m:${remainingSeconds}s`
    }
    const checkBoxHandleSource = (event, index) => {
        setSourceLang([])
        if (event.target.checked) {
            const srcLang = sourceLang;
            srcLang[index].selected = true
            setTimeout(() => {
                setSourceLang(srcLang)

            }, 100);

        } else {
            const srcLang = sourceLang;
            srcLang[index].selected = false
            setTimeout(() => {
                setSourceLang(srcLang)

            }, 100);
        }

    }
    const setSelectedValue = (value) => {
        setTimeout(() => {
            setFilterValue(value);
        }, 100);
    }

    const updateVideoStatus = async (selectValue, id) => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.put(config.API_URL + "/admin/dashboard/dubbed/video/" + id, { status: selectValue });
            if (getCompany.status === 200) {
                toast.success("Video status update successfully.")
                getAllVideos('', 0)
            }
        } catch (error) {
            toast.error("Something wrong!. Try again after sometime", error.message)
            console.log(error)
        }
    }




    useEffect(() => {
        getAllVideos('', 0);
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">
                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Videos list</Card.Title>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col xs={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control value={searchValue} type="text" placeholder="search by title" onChange={(v) => {
                                                setSearchValue(v.target.value)
                                            }} />
                                        </Form.Group>

                                    </Col>
                                    <Col >

                                        <Form.Select className="cart_title_button" aria-label="Default select example" value={filterValue} onChange={async (v) => {
                                            setSelectedValue(v.target.value);
                                        }}>
                                            <option value="">Please Select filter</option>
                                            {config.VIDEO_STATUS.map((v) => {
                                                return <option value={v.value}>{v.name}</option>
                                            })}

                                        </Form.Select>
                                    </Col>
                                    <Col >
                                        <Dropdown className="d-inline mx-2" autoClose="outside">
                                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                                Source Lang
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {sourceLang.length > 0 && sourceLang.map((v, index) => {
                                                    return (<Dropdown.Item ><input type="checkbox" onClick={(event) => checkBoxHandleSource(event, index)} checked={v.selected} style={{ marginRight: "10px" }} ></input>{v.text}</Dropdown.Item>)
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col >
                                        <Dropdown className="d-inline mx-2" autoClose="outside">
                                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                                Target Lang
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {targetLang.length > 0 && targetLang.map((v, index) => {
                                                    return (<Dropdown.Item ><input type="checkbox" onClick={(event) => checkBoxHanlde(event, index)} checked={v.selected} style={{ marginRight: "10px" }} ></input>{v.text}</Dropdown.Item>)
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col >
                                        <Button variant="primary" onClick={() => getAllVideos('', 0)} >Submit</Button>
                                    </Col>

                                </Row>

                            </Card.Header>
                            <Card.Body className="table_video table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No</th>
                                            <th className="border-0">Id</th>

                                            <th className="border-0">Name</th>
                                            <th className="border-0">Title</th>
                                            <th className="border-0">Source Language</th>
                                            <th className="border-0">Target Language</th>
                                            <th className="border-0">Translator Assigned</th>
                                            <th className="border-0">QA Assigned</th>
                                            <th className="border-0">Video Status</th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0">Duration</th>

                                            <th className="border-0">Review Request time</th>

                                            <th className="border-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {videos.length > 0 &&
                                            videos.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>

                                                        {/* <td>{item.translator}</td> */}
                                                        <td>{item._id}</td>

                                                        <td>{item.client_info[0].name}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.sourceLanguage}</td>
                                                        <td>{item.targetLanguage}</td>
                                                        {item.assignedTranslator.length > 0 && <td>{item.assignedTranslator[0].translator_company[0].name}</td>}
                                                        {item.assignedTranslator.length <= 0 && <td>----</td>}

                                                        {item.qaTranslator.length > 0 && <td>{item.qaTranslator[0].QA_company[0].name}</td>}
                                                        {item.qaTranslator.length <= 0 && <td>----</td>}

                                                        <td>{item.status}</td>

                                                        {(item.videoReviewInformation !== undefined && item.videoReviewInformation.status !== undefined) && <td>{item.videoReviewInformation.status}</td>}
                                                        {(item.videoReviewInformation === undefined || item.videoReviewInformation.status === undefined) && <td>----</td>}
                                                        <td>{secondsToMinutesAndSeconds(item.dubbedVideoDuration)}</td>
                                                        {(item.videoReviewInformation !== undefined && item.videoReviewInformation.created_at !== undefined) && <td>{moment(item.videoReviewInformation.created_at).format("DD-MM-YY")}</td>}
                                                        {(item.videoReviewInformation === undefined || item.videoReviewInformation.created_at === undefined) && <td>---</td>}




                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                    Action
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={
                                                                        () => {
                                                                            setType(1);
                                                                            setSelectItem(item);
                                                                            setModalShow(true);
                                                                        }
                                                                    } >Assigned Translator</Dropdown.Item>
                                                                    <Dropdown.Item onClick={
                                                                        () => {
                                                                            setType(2);
                                                                            setSelectItem(item);
                                                                            setModalShow(true);
                                                                        }
                                                                    }>Assigned QA</Dropdown.Item>

                                                                    {item.videoReviewInformation !== undefined && item.videoReviewInformation.status !== undefined && item.videoReviewInformation.status === 'SENT_TO_CLIENT' && <Dropdown.Item onClick={
                                                                        () => {
                                                                            updateVideoStatus("WAITING_QA", item._id);
                                                                        }
                                                                    }>Waiting for QA </Dropdown.Item>}


                                                                    {item.videoReviewInformation !== undefined && item.videoReviewInformation.status !== undefined && item.videoReviewInformation.status === 'APPROVED' && <Dropdown.Item onClick={
                                                                        () => {
                                                                            updateVideoStatus("WAITING_QA", item._id);
                                                                        }
                                                                    }>Waiting for QA </Dropdown.Item>}
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>


            {modalShow && selectItem !== '' && <AssignTranslatorOrQaModal type={type} show={modalShow} itemData={selectItem} onHide={async (type, value, tatDate) => {
                if (type === 1) {
                    assignedTranslator(value, tatDate);
                    setSelectItem('')
                    setModalShow(false);
                } else {
                    setSelectItem('')
                    setModalShow(false)
                }
            }}
            ></AssignTranslatorOrQaModal>}

        </>
    );
}

export default Videos;