import React, { useState, useEffect } from 'react';
import {
  Modal,
  Col,
  Row,
  Button,
  Form,
} from "react-bootstrap";
import * as _ from 'lodash'
import toast from "react-hot-toast"
import httpClient from 'http-common'
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import ReactPlayer from 'react-player';
import config from "constants/config"
import { filterGcsUploadFileName } from 'utils/index';

function PublicVideoModal(props) {
  const { show, onHide, selectItem } = props;
  const [industris, setIndustries] = useState([]);
  const [message, setMessage] = useState('');
  const [isLinkFromYoutube, setIsLinkFromYoutube] = useState(selectItem?.isVideoEditing && selectItem?.original_video_url?.includes('youtube.com') ? true : false);
  const [disabled, setDisabled] = useState(selectItem?.isVideoEditing ? false : true);
  const [showPlayer, setShowPlayer] = useState(false);

  const closeModal = (value) => {
    onHide(value);
  }

  // generating signedurl to upload file to GCS
  const fetchGCSVideoUploadSignedUrl = async (fileDetails) => {
    try {
      const res = await httpClient.post(config.GCS_SIGNED_API_URL, fileDetails, {
        headers: {
          'Content-Type': 'application/json',
          'x-app-header': 'cms',
          'x-upload-content-length': fileDetails.size,
          'x-content-type': fileDetails.type,
        },
      })
      return res?.data?.data
    } catch (error) {
      if (error && error['response']) toast(error['response'].data.message, { type: 'error' })
      else toast('Something went wrong', { type: 'error' })
      throw error
    }
  }

  // uploading file to GCS
  const uploadFileToGCS = async (signedUrl, file) => {
    try {
      await httpClient.put(signedUrl, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'x-upload-content-length': file.size,
          'x-app-header': 'cms',
          'x-content-type': file.type,
        },
      })
    } catch (error) {
      if (error && error['response']) toast(error['response'].data.message, { type: 'error' })
      else toast('Something went wrong', { type: 'error' })
      throw error
    }
  }

  // fetching industry for video
  const getAllIndustry = async () => {
    try {
      const getAllIndustry = await httpClient.get(config.API_URL + "/admin/dashboard/public_videos/industry")
      setIndustries(getAllIndustry.data.data);
    } catch (error) {
      console.log(error)
    }

  }

  // Creating public video 
  const handleVideoAdd = async (values, setSubmitting) => {
    try {
      setSubmitting(true)
      const dubbedLanguageUploadedData = []
      // uploading thumbnail
      const thumnailSignedData = values?.thumbnailUrl?.size && await fetchGCSVideoUploadSignedUrl({
        fileName: filterGcsUploadFileName(values.thumbnailUrl.name, 15),
        size: values.thumbnailUrl.size,
        type: values.thumbnailUrl.type,
      })
      values?.thumbnailUrl?.size && await uploadFileToGCS(thumnailSignedData.url, values.thumbnailUrl)

      // uploading checklist report
      const checklistReportSignedData = values?.qualityChecklistLink?.size && await fetchGCSVideoUploadSignedUrl({
        fileName: filterGcsUploadFileName(values.qualityChecklistLink.name, 15),
        size: values.qualityChecklistLink.size,
        type: values.qualityChecklistLink.type,
      })
      values?.qualityChecklistLink?.size && await uploadFileToGCS(checklistReportSignedData.url, values.qualityChecklistLink)

      // uploading original video file
      let originalVideoSignedData
      if (!isLinkFromYoutube) {
        originalVideoSignedData = values?.originalVideoFile?.size && await fetchGCSVideoUploadSignedUrl({
          fileName: filterGcsUploadFileName(values.originalVideoFile.name, 15),
          size: values.originalVideoFile.size,
          type: values.originalVideoFile.type,
        })
        values?.originalVideoFile?.size && await uploadFileToGCS(originalVideoSignedData.url, values.originalVideoFile)
      }

      // uploading dubbed video url
      for (const [index, dubbedLanguageData] of values?.dubbedLanguages.entries()) {
        const dubbedVideoUrlSignedData = dubbedLanguageData?.dubbedVideoUrl?.size && await fetchGCSVideoUploadSignedUrl({
          fileName: filterGcsUploadFileName(dubbedLanguageData.dubbedVideoUrl.name, 15),
          size: dubbedLanguageData.dubbedVideoUrl.size,
          type: dubbedLanguageData.dubbedVideoUrl.type,
        })
        dubbedLanguageData?.dubbedVideoUrl?.size && await uploadFileToGCS(dubbedVideoUrlSignedData.url, dubbedLanguageData.dubbedVideoUrl)

        const payloadToSetInState = {
          video_title: dubbedLanguageData.dubbedVideoTitle,
          checklist_download_link_name: checklistReportSignedData?.fileName || selectItem?.multiple_lang[0].checklist_download_link || '',
          dubbed_video_name: dubbedVideoUrlSignedData?.fileName || selectItem?.multiple_lang[index].dubbed_video_url || '',
          isActive: true,
          target_language: dubbedLanguageData.dubbedVideoLanguage,
          thumbnail_name: thumnailSignedData?.fileName || selectItem?.thumbnail_url || ''
        }
        dubbedLanguageUploadedData.push(payloadToSetInState)
      }

      // Main video upload payload
      const payloadToCreateVideo = {
        video_title: values.videoTitle,
        industry_id: values.industry,
        industry: [_.find(industris, (vt) => vt._id === values.industry)],
        isActive: true,
        multiple_lang: dubbedLanguageUploadedData,
        original_language: values.originalLanguage,
        original_video_name: !isLinkFromYoutube ? (originalVideoSignedData?.fileName || selectItem?.original_video_url || '') : undefined,
        original_video_url: isLinkFromYoutube ? (values?.originalVideoUrl || selectItem?.original_video_url || '') : undefined,
        thumbnail_name: thumnailSignedData?.fileName || selectItem?.thumbnail_url || ''
      }
      console.log("isYoutubeLink ::::: ", isLinkFromYoutube)

      if (selectItem?.isVideoEditing) {
        await httpClient.put(config.API_URL + `/admin/dashboard/public_video/${selectItem._id}`, payloadToCreateVideo)
      } else await httpClient.post(config.API_URL + "/admin/dashboard/public_videos", payloadToCreateVideo)
      setSubmitting(false)
      closeModal(1)
      toast.success("Video save successfully")
    }
    catch (error) {
      console.log(error.message)
      toast.error('Something went wrong')
      setSubmitting(false)
      closeModal(0)
    }
  }

  // youtube video url validation
  const checkYouTubeVideo = (url) => {
    if (typeof url !== 'string') {
      setDisabled(true);
      setShowPlayer(false);
      setIsLinkFromYoutube(false);

      return true;
    }
    let finalUrl = url;
    const isShortsVideo = url.includes('youtube.com/shorts');
    if (isShortsVideo) {
      finalUrl = url.split('?')[0].replace('shorts/', 'watch?v=');
    }
    const isPlayable = ReactPlayer.canPlay(finalUrl);
    setShowPlayer(isPlayable);
    setDisabled(!isPlayable);
    isPlayable ? setIsLinkFromYoutube(true) : setIsLinkFromYoutube(false);

    return isPlayable;
  };


  // generating uploaded video file meta info
  const loadVideoMetadata = (fileObj) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = window.URL.createObjectURL(fileObj);

      const handleLoadedMetadata = () => {
        resolve(video.duration / 60);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);

      video.load();
    });
  };

  // check uploaded file validation
  const checkFileIsValid = async (file) => {
    const response = await loadVideoMetadata(file).then((duration) => {
      const fileSizeInMB = file?.size / 1024 / 1024;
      if (fileSizeInMB < 500 && file.type === 'video/mp4') {
        return true;
      } else {
        return false;
      }
    }).catch(error => {
      return false
    })
    return response
  }

  // Thumbnail file validation
  const checkFileTypeValidation = async (file, regexForFileType) => {
    if (file?.size && regexForFileType.test(file.name)) {
      return true
    } else return false
  }

  // Uploaded original video file validation
  const checkOriginalVideoSize = async (file) => {
    if (!(file && file?.size)) return true;
    const response = await checkFileIsValid(file)
    if (response === true) {
      setDisabled(false);
    } else setDisabled(true)
    setIsLinkFromYoutube(false)
    return response
  };

  // Uploaded dubbed video file validation
  const checkDubbedVideoSize = async (file) => {
    if (!(file && file?.size)) return false;
    const response = await checkFileIsValid(file)
    return response
  };

  // Form validation schema
  const validationSchema = Yup.object().shape({
    videoTitle: Yup.string().required("Required"),
    originalLanguage: Yup.string().required("Required"),
    thumbnailUrl: Yup.mixed().when('selectItem', ([], schema, thumbnailObj) => {
      return (selectItem?.isVideoEditing && !(thumbnailObj?.value?.size)) ?
        Yup.mixed().nullable() :
        Yup.mixed().required('Required')
          .test(
            'fileType',
            'Please upload valid image file',
            (file) => checkFileTypeValidation(file, /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i),
          )
    }),
    industry: Yup.string().required("Required"),
    originalVideoUrl: Yup.string()
      .when(['originalVideoFile', 'selectItem'], ([originalVideoFile], schema, valObject) => {
        let checkRequiredValidation = !(originalVideoFile && originalVideoFile?.size)
        if (selectItem?.isVideoEditing) {
          checkRequiredValidation = valObject?.value?.trim()?.length ? true : (!selectItem?.original_video_url.includes('gs://') && !(originalVideoFile && originalVideoFile?.size))
        }
        return checkRequiredValidation
          ? Yup.string()
            .required('Youtube video link or local video is Required.').test(
              'custom',
              'This video cannot be fetched',
              checkYouTubeVideo,
            )
          : Yup.string();
      }),
    originalVideoFile: Yup.mixed()
      .when('originalVideoUrl', ([originalVideoUrl], schema, valObject) => {
        let checkRequiredValidation = !originalVideoUrl || !originalVideoUrl?.trim()?.length
        if (selectItem?.isVideoEditing) {
          checkRequiredValidation = valObject?.value?.size ? true : false
        }
        return checkRequiredValidation
          ? Yup.mixed()
            .required('Youtube video link or local video is Required.')
            .test(
              'fileSize',
              'File is required and size should not exceed 500MB',
              (file) => checkOriginalVideoSize(file),
            )
          : Yup.mixed().nullable();
      }),
    qualityChecklistLink: Yup.mixed().when('selectItem', ([qualityChecklistLink]) => {
      return (selectItem?.isVideoEditing && !qualityChecklistLink?.size) ?
        Yup.mixed().nullable() :
        Yup.mixed().required('Required')
          .test(
            'fileType',
            'Please upload valid file.',
            (file) => checkFileTypeValidation(file, /(\.pdf)$/i),
          )
    }),
    dubbedLanguages: Yup.array().of(
      Yup.object().shape({
        dubbedVideoTitle: Yup.string().required("Required"),
        dubbedVideoUrl: Yup.mixed().when('selectItem', ([], schema, dubbedVideoUrl) => {
          return (selectItem?.isVideoEditing && !dubbedVideoUrl?.size) ?
            Yup.mixed().nullable() :
            Yup.mixed().required('Required').test('custom', 'File size should not exceed 500MB.', (file) => checkDubbedVideoSize(file))
        }),
        dubbedVideoLanguage: Yup.string().required("Required"),
      }),
    ).min(1, 'Required'),
  }, ['originalVideoFile', 'originalVideoUrl'])

  useEffect(() => {
    getAllIndustry();
  }, []);


  return (
    <Modal {...props} size="xl" backdrop="static">
      <Formik
        initialValues={{
          videoTitle: selectItem?.video_title || "",
          originalLanguage: selectItem?.original_language || "",
          originalVideoUrl: selectItem?.original_video_url?.includes('youtube.com') ? selectItem?.original_video_url : "",
          industry: selectItem?.industry_id || "",
          thumbnailUrl: null,
          dubbedLanguages: selectItem?.multiple_lang.map(lang =>
            ({ dubbedVideoTitle: lang.video_title, dubbedVideoUrl: null, dubbedVideoLanguage: lang.target_language })) ||
            [{ dubbedVideoTitle: "", dubbedVideoUrl: null, dubbedVideoLanguage: "" }],
          originalVideoFile: null,
          qualityChecklistLink: null,
        }}
        validateOnMount
        validateOnBlur
        onSubmit={async (values, { setSubmitting }) => { }}
        validationSchema={validationSchema}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            setSubmitting
          } = props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Add Public video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md="4">
                      <Form.Group className="mb-3" controlId="videoTitle">
                        <label htmlFor="videoTitle">
                          Video Title
                        </label>
                        <Form.Control
                          placeholder="Video Title"
                          type="text"
                          name="videoTitle"
                          value={values.videoTitle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.videoTitle && touched.videoTitle && "error"}
                        />
                        {errors.videoTitle && touched.videoTitle && (
                          <div className="input-feedback">{errors.videoTitle}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group className="mb-3" controlId="industry">
                        <label htmlFor="industry">
                          Industry
                        </label>
                        <Form.Select
                          placeholder="industry"
                          type="text"
                          name="industry"
                          value={values.industry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={(errors.industry && touched.industry) ? "error form-control" : "form-control"}
                        >
                          <option key='industry-placeholder' value="">Select Industry</option>
                          {industris.map(industry => (
                            <option key={industry._id} value={industry._id}>{industry.name}</option>
                          ))}
                        </Form.Select>
                        {errors.industry && touched.industry && (
                          <div className="input-feedback">{errors.industry}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group className="mb-3" controlId="originalLanguage">
                        <label htmlFor="originalLanguage">
                          Original Language
                        </label>
                        <Form.Select
                          placeholder="Original Language"
                          type="text"
                          name="originalLanguage"
                          value={values.originalLanguage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={(errors.originalLanguage && touched.originalLanguage) ? "error form-control" : "form-control"}
                        >
                          <option key='language-placeholder' value="">Select Language</option>
                          {config.LANGUAGES_ASSENTS.map(lang => (
                            <option key={lang.key} value={lang.value}>{lang.text}</option>
                          ))}
                        </Form.Select>
                        {errors.originalLanguage && touched.originalLanguage && (
                          <div className="input-feedback">{errors.originalLanguage}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3" controlId="thumbnailUrl">
                        <label htmlFor="thumbnailUrl">thumbnail</label>
                        <Form.Control
                          type="file"
                          name="thumbnailUrl"
                          onChange={e => {
                            setFieldValue(
                              'thumbnailUrl',
                              e?.target?.files?.length ? e?.target?.files[0] : null,
                            )
                          }}
                          accept=".png,.jpg,.jpeg,.gif,.svg"
                          onBlur={handleBlur}
                          className={(errors.thumbnailUrl && touched.thumbnailUrl) ? "error form-control" : "form-control"}
                        />
                        {errors.thumbnailUrl && touched.thumbnailUrl && (
                          <div className="input-feedback">{errors.thumbnailUrl}</div>
                        )}
                        {selectItem?.isVideoEditing && selectItem?.thumbnail_url && !values.thumbnailUrl?.size &&
                          <div className="input-value">{selectItem?.thumbnail_url}</div>}
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3" controlId="qualityChecklistLink">
                        <label htmlFor="qualityChecklistLink">Quality Checklist Report</label>
                        <Form.Control
                          type="file"
                          name="qualityChecklistLink"
                          onChange={e => {
                            setFieldValue(
                              'qualityChecklistLink',
                              e?.target?.files?.length ? e?.target?.files[0] : null,
                            )
                          }}
                          accept=".pdf"
                          onBlur={handleBlur}
                          className={(errors.qualityChecklistLink && touched.qualityChecklistLink) ? "error form-control" : "form-control"}
                        />
                        {errors.qualityChecklistLink && touched.qualityChecklistLink && (
                          <div className="input-feedback">{errors.qualityChecklistLink}</div>
                        )}
                        {selectItem?.isVideoEditing && selectItem?.multiple_lang[0]?.checklist_download_link && !values.qualityChecklistLink?.size &&
                          <div className="input-value">{selectItem?.multiple_lang[0]?.checklist_download_link}</div>}
                      </Form.Group>
                    </Col>
                    <Col md="5">
                      <Form.Group className="mb-3" controlId="originalVideoUrl">
                        <label htmlFor="originalVideoUrl">
                          Original Video
                        </label>
                        <Form.Control
                          placeholder="Youtube Video Url"
                          type="text"
                          name="originalVideoUrl"
                          value={values.originalVideoUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={(errors.originalVideoUrl && touched.originalVideoUrl) ? "error form-control" : "form-control"}
                          disabled={
                            (values?.originalVideoFile && values.originalVideoFile?.size)
                              ? true
                              : false
                          }
                        />
                        {errors.originalVideoUrl && touched.originalVideoUrl && (
                          <div className="input-feedback">{errors.originalVideoUrl}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Or</Col>
                    <Col md="5">
                      <Form.Group className="mb-3" controlId="originalVideoFile">
                        <label htmlFor="originalVideoFile">Original Video</label>
                        <Form.Control
                          type="file"
                          name="originalVideoFile"
                          onChange={(e) => {
                            setFieldValue(
                              'originalVideoFile',
                              e?.target?.files?.length ? e?.target?.files[0] : null,
                            );
                            if (
                              e?.target?.files?.length &&
                              values?.originalVideoUrl?.trim()?.length
                            ) {
                              setFieldValue('originalVideoUrl', '');
                            }
                          }}
                          accept=".mp4"
                          onBlur={handleBlur}
                          className={(errors.originalVideoFile && touched.originalVideoFile) ? "error form-control" : "form-control"}
                        />
                        {errors.originalVideoFile && touched.originalVideoFile && (
                          <div className="input-feedback">{errors.originalVideoFile}</div>
                        )}
                        {selectItem?.isVideoEditing && selectItem?.original_video_url?.includes('dubpro-assets-dev') && !values.originalVideoFile?.size &&
                          <div className="input-value">{selectItem?.original_video_url}</div>}
                      </Form.Group>
                    </Col>
                    {showPlayer && values.originalVideoUrl &&
                      <Col md="5" className="mb-3">
                        <ReactPlayer
                          url={values.originalVideoUrl}
                          width="100%"
                          height={250}
                        />
                      </Col>}
                    <hr />
                    <h4>Dubbed Video Section</h4>
                    <FieldArray
                      name="dubbedLanguages"
                      render={(arrayHelpers) => (
                        <>
                          {
                            values.dubbedLanguages.map((dubbedData, index) => (
                              <>
                                <Col md="3">
                                  <Form.Group className="mb-3" controlId={`dubbedVideoTitle${index}`}>
                                    <label htmlFor={`dubbedVideoTitle${index}`}>
                                      Dubbed Video Title
                                    </label>
                                    <Form.Control
                                      placeholder="Dubbed Video Title"
                                      type="text"
                                      name={`dubbedLanguages.${index}.dubbedVideoTitle`}
                                      value={dubbedData.dubbedVideoTitle}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={(errors.dubbedLanguages?.[index]?.dubbedVideoTitle && touched.dubbedLanguages?.[index]?.dubbedVideoTitle) ? "error form-control" : "form-control"}
                                    />
                                    {(errors?.dubbedLanguages?.[index]?.dubbedVideoTitle && touched?.dubbedLanguages?.[index]?.dubbedVideoTitle) && (
                                      <div className="input-feedback">{errors.dubbedLanguages[index].dubbedVideoTitle}</div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group className="mb-3" controlId={`dubbedVideoLanguage${index}`}>
                                    <label htmlFor={`dubbedVideoLanguage${index}`}>
                                      Dubbed Language
                                    </label>
                                    <Form.Select
                                      placeholder="Dubbed Language"
                                      type="text"
                                      name={`dubbedLanguages.${index}.dubbedVideoLanguage`}
                                      value={dubbedData.dubbedVideoLanguage}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={(errors.dubbedLanguages?.[index]?.dubbedVideoLanguage && touched.dubbedLanguages?.[index]?.dubbedVideoLanguage) ? "error form-control" : "form-control"}
                                    >
                                      <option key='dubbed-language-placeholder' value="">Select Language</option>
                                      {config.LANGUAGES_ASSENTS.map(lang => (
                                        <option key={lang.key} value={lang.value}>{lang.text}</option>
                                      ))}
                                    </Form.Select>
                                    {errors?.dubbedLanguages?.[index]?.dubbedVideoLanguage && touched?.dubbedLanguages?.[index]?.dubbedVideoLanguage && (
                                      <div className="input-feedback">{errors.dubbedLanguages[index].dubbedVideoLanguage}</div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group className="mb-3" controlId={`dubbedVideoUrl${index}`}>
                                    <label htmlFor={`dubbedVideoUrl${index}`}>Dubbed Video</label>
                                    <Form.Control
                                      type="file"
                                      name={`dubbedLanguages.${index}.dubbedVideoUrl`}
                                      onChange={e => {
                                        setFieldValue(
                                          `dubbedLanguages.${index}.dubbedVideoUrl`,
                                          e?.target?.files?.length ? e?.target?.files[0] : null,
                                        )
                                      }}
                                      accept=".mp4"
                                      onBlur={handleBlur}
                                      className={(errors.dubbedLanguages?.[index]?.dubbedVideoUrl && touched.dubbedLanguages?.[index]?.dubbedVideoUrl) ? "error form-control" : "form-control"}
                                    />
                                    {errors?.dubbedLanguages?.[index]?.dubbedVideoUrl && touched?.dubbedLanguages?.[index]?.dubbedVideoUrl && (
                                      <div className="input-feedback">{errors.dubbedLanguages[index].dubbedVideoUrl}</div>
                                    )}
                                    {selectItem?.isVideoEditing && selectItem?.multiple_lang[index]?.dubbed_video_url && !values.dubbedLanguages?.[index]?.dubbedVideoUrl?.size &&
                                      <div className="input-value">{selectItem?.multiple_lang[index]?.dubbed_video_url}</div>}
                                  </Form.Group>
                                </Col>
                                <Col md="3" className="mt-4">
                                  <Button
                                    variant="danger"
                                    onClick={() => arrayHelpers.remove(index)}
                                    disabled={values?.dubbedLanguages?.length === 1}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </>
                            ))
                          }
                          <Button
                            variant="primary"
                            onClick={() =>
                              arrayHelpers.push({ dubbedVideoTitle: "", dubbedVideoUrl: null, dubbedVideoLanguage: "", })
                            }
                          >
                            Add Entry
                          </Button>
                        </>
                      )}>

                    </FieldArray>
                  </Row>

                  <h5 style={{ color: "red", marginTop: "10px" }}>{message}</h5>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => closeModal(0)}>
                    Close
                  </Button>
                  <Button variant="primary" disabled={!isValid || isSubmitting || disabled} onClick={() => handleVideoAdd(values, setSubmitting)}>
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}

      </Formik>
    </Modal >

  );
}

export default PublicVideoModal;