import { Popover, OverlayTrigger } from 'react-bootstrap';
const OverlayComponent = ({ _id, children, popoverBody, trigger, popoverHeader, placement, ...props }) => {
  const popover = (
    <Popover id={`popover-basic-${_id}`}>
      <Popover.Header as="h3">{popoverHeader}</Popover.Header>
      <Popover.Body>
        {popoverBody}
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={popover}
      key={_id}
      {...props}
    >
      {children}
    </OverlayTrigger >
  );
};

export default OverlayComponent