const config = {
    API_URL: process.env.BASE_URL || "https://api.cms.dubpro.ai/api/v0",
    V1_VIDEO_API_URL: process.env.BASE_URL || "https://api.video.dubpro.ai/video-service",
    V1_AUTH_API_URL: process.env.BASE_URL || "https://api.auth.dubpro.ai/auth",


    GCS_SIGNED_API_URL: 'https://api.signed.dubpro.ai',
    LANGUAGE_MAPS: [
        { name: "hi", value: "Hindi", isSelected: false },
        { name: "en", value: "English", isSelected: false },
        { name: "es", value: "Spanish", isSelected: false },
        { name: "fr", value: "French", isSelected: false },
        { name: "de", value: "German", isSelected: false },
        { name: "it", value: "Italian", isSelected: false },
        { name: "ta", value: "Tamil", isSelected: false },
        { name: "te", value: "Telugu", isSelected: false },
        { name: "bn", value: "Bengali", isSelected: false },
        { name: "kn", value: "Kannada", isSelected: false }
    ],

    SOURCE_LANGUAGE_MAPS: [
        { name: "hi", value: "Hindi", isSelected: 0 },
        { name: "en", value: "English", isSelected: 0 },
        { name: "es", value: "Spanish", isSelected: 0 },
        { name: "fr", value: "French", isSelected: 0 },
        { name: "de", value: "German", isSelected: 0 },
        { name: "it", value: "Italian", isSelected: 0 },
        { name: "ta", value: "Tamil", isSelected: 0 },
        { name: "te", value: "Telugu", isSelected: 0 },
        { name: "bn", value: "Bengali", isSelected: 0 },
        { name: "kn", value: "Kannada", isSelected: 0 }
    ],

    TARGET_LANGUAGE_MAPS: [
        { name: "hi", value: "Hindi", isSelected: 0 },
        { name: "en", value: "English", isSelected: 0 },
        { name: "es", value: "Spanish", isSelected: 0 },
        { name: "fr", value: "French", isSelected: 0 },
        { name: "de", value: "German", isSelected: 0 },
        { name: "it", value: "Italian", isSelected: 0 },
        { name: "ta", value: "Tamil", isSelected: 0 },
        { name: "te", value: "Telugu", isSelected: 0 },
        { name: "bn", value: "Bengali", isSelected: 0 },
        { name: "kn", value: "Kannada", isSelected: 0 }
    ],
    VIDEO_STATUS: [
        { name: "Not Assigned", value: "NOT_ASSIGNED" },
        { name: "Not Started", value: "NOT_STARTED" },
        { name: "Dubbing Started", value: "DUBBING_STARTED" },
        { name: "Waiting QA", value: "WAITING_QA" },
        { name: "Sent to Client", value: "SENT_TO_CLIENT" },
        { name: "Approved", value: "APPROVED" },
        { name: "Rejected", value: "REJECTED" },
        { name: "Churned", value: "CHURNED" },
        { name: "Re-review Requested", value: "RE_REVIEW_REQUESTED" }
    ],


    SQS_LISTS: [
        {
            "name": "English",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-clone-en-queue",
            "isActive": true
        },
        {
            "name": "Hindi IPA",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-clone-ipa-queue",
            "isActive": true
        },
        {
            "name": "Bengali",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-clone-bengali-queue",
            "isActive": true
        },
        {
            "name": "Fastpitch Hindi IPA",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-clone-fp-ipa-queue",
            "isActive": true
        },
        {
            "name": "English IPA",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-clone-ipa-eng-queue",
            "isActive": true
        },
        {
            "name": "English IPA(Resgard)",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-eng-ipa-resgrad-api-test",
            "isActive": true
        },
        {
            "name": "Hindi IPA(Resgard)",
            "value": "https://sqs.us-east-1.amazonaws.com/296056014032/deepsync-hindi-ipa-resgrad-api-test",
            "isActive": true
        }
    ],

    LANGUAGES_ASSENTS: [
        { key: 'en-US', value: 'en-US', text: 'English', selected: false },
        { key: 'hi-IN', value: 'hi-IN', text: 'Hindi', selected: false },
        { key: 'es-US', value: 'es-US', text: 'Spanish', selected: false },
        { key: 'ta-IN', value: 'ta-IN', text: 'Tamil', selected: false },
        { key: 'fr-US', value: 'fr-US', text: 'French', selected: false },
    ],
    LANGUAGES_ASSENTS_SOURCE: [
        { key: 'en-US', value: 'en-US', text: 'English', selected: false },
        { key: 'hi-IN', value: 'hi-IN', text: 'Hindi', selected: false },
        { key: 'es-US', value: 'es-US', text: 'Spanish', selected: false },
        { key: 'ta-IN', value: 'ta-IN', text: 'Tamil', selected: false },
        { key: 'fr-US', value: 'fr-US', text: 'French', selected: false },
    ],

    AI_MODELS: [
        { key: "Eleven Multilingual V2", value: "eleven_multilingual_v2" },
        { key: "Eleven Turbo V2.5", value: "eleven_turbo_v2_5" }
    ]

}
export default config;

