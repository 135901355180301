import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "constants/config"
import AddTranslator from "components/Shared/AddTranslator"
import { useLocation, useHistory } from "react-router-dom";
import httpClient from 'http-common'
import * as _ from "lodash"
import ConfirmModal from "components/Shared/ConfirmModal"
import toast from "react-hot-toast"

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Dropdown,
} from "react-bootstrap";

function Translator() {
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [selectItem, setSelectItem] = useState(null);

    const [modalConfirmShow, setModalConfirmShow] = useState(false);

    const [selectItemData, setSelectItemData] = useState(null);


    const [company, setCompany] = useState([]);
    let history = useHistory();


    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? "H " : "H ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? "M " : "M ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? "S " : "S") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const getAllCompany = async () => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.post(config.API_URL + "/admin/dashboard/translators")
            setCompany(getCompany.data.data)

        } catch (error) {
            console.log(error)
        }

    }
    const findLanguage = (v) => {
        const findLang = _.find(config.LANGUAGE_MAPS, (vt) => vt.name === v);
        if(!findLang){
            return ''
        }else {
            return findLang.value;

        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === undefined && token === null) {
            history.push('/login')
        }
        getAllCompany()
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">
                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Translator list</Card.Title>
                                    <Button variant="primary" className="cart_title_button" onClick={async () => {
                                        await setSelectItemData(null);
                                        await setModalShow(true)
                                    }}>Add Translator</Button>

                                </Row>


                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">Num</th>
                                            {/* <th className="border-0">ID</th> */}
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Email</th>
                                            <th className="border-0">Source Accent</th>
                                            <th className="border-0">Target Accent</th>
                                            <th className="border-0">Contact Number</th>
                                            <th className="border-0">Price</th>
                                            <th className="border-0">Assigned</th>
                                            <th className="border-0">Reviewed</th>
                                            <th className="border-0">Pending</th>

                                            <th className="border-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            company.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>

                                                        {/* <td>{item.translator}</td> */}
                                                        <td>{item.company.name}</td>
                                                        <td>{item.company.email}</td>
                                                        <td>
                                                            <ul class="list_view">
                                                                {item.sourceAccent.length > 0 && item.sourceAccent.map((v) => {
                                                                    return <li>{findLanguage(v)}</li>
                                                                })}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul class="list_view">
                                                                {item.targetAccent.length > 0 && item.targetAccent.map((v) => {
                                                                    return <li>{findLanguage(v)}</li>
                                                                })}
                                                            </ul>
                                                        </td>
                                                        <td>{item.contactNum}</td>
                                                        <td>{item.pricing}</td>
                                                        <td>{item.dubbedVideos.number_of_video_assigned}</td>
                                                        <td>{item.dubbedVideos.number_of_video_reviewed}</td>
                                                        <td>{item.dubbedVideos.number_of_video_pending}</td>
                                                        <td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                        Action
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => {
                                                                            setSelectItemData(item);
                                                                            setModalShow(true);
                                                                        }}>Edit</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => {
                                                                            setMessage("Are sure want to delete!")
                                                                            setSelectItem(item);
                                                                            setModalConfirmShow(true);
                                                                        }}>Delete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            </td>
                                                        </td>
                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {selectItemData === null && <AddTranslator setSelectItemData={setSelectItemData} show={modalShow} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setModalShow(false)
                } else {
                    setModalShow(false)
                }
            }} />}

            {selectItemData != null && <AddTranslator setSelectItemData={setSelectItemData} itemData={selectItemData} show={modalShow} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setModalShow(false)
                } else {
                    setModalShow(false)
                }
            }} />}


            <ConfirmModal message={message} setMessage={setMessage} show={modalConfirmShow} onHide={async (number) => {
                if (number === 1) {
                    const token = localStorage.getItem('token')
                    try {
                        const response = await httpClient.delete(config.API_URL + "/admin/dashboard/translator/" + selectItem._id)
                        setSelectItem(null);
                        getAllCompany();
                        setModalConfirmShow(false)
                        toast.success("User Deleted successfully")
                    } catch (error) {
                        setModalConfirmShow(false)
                        toast.error(error.response.data)
                    }
                } else {
                    setModalConfirmShow(false)
                }
            }}
            ></ConfirmModal>


        </>
    );
}

export default Translator;