import httpClient from 'http-common';
import httpClientV1 from 'http-common-v1';
import * as _ from "lodash";
import { useEffect, useState } from 'react';
import {
    Button, Col, Form, Modal, Row
} from "react-bootstrap";

import config from "constants/config";
import toast from "react-hot-toast";

function AddVoiceCompanyModal(props) {
    const { show, onHide, message, setMessage, itemData } = props


    const [voiceName, setVoiceName] = useState(null);
    const [voiceId, setVoiceId] = useState(null);
    const [url, setUrl] = useState(null);
    const [accent, setAccent] = useState(null);
    const [gender, setGender] = useState(null);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {

    }, []);
    const getAllLangues = async () => {
        try {
            const response = await httpClientV1.get(config.V1_AUTH_API_URL + '/languages?page=1&limit=10');
            setLanguages(response.data.data.langauges)
            console.log(response);
        } catch (error) {
            console.log(error);

        }


    }


    const updateCompanyDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const headerData = {
                'Authorization': `Bearer ${token}`
            }
            const payloadData = { voice_name: voiceName, voice_id: voiceId, "url": url, "accent": accent, "gender": gender };
            if (url === null || url === "") {
                delete payloadData.url
            }
            const findAccentId = _.find(languages, (v) => v.code === accent);

            const v1Payload = {
                "name": voiceName,
                "sourceId": "110011",
                "url": url || "Josh_m_TxGEqnHWrfWFTfGW9XjX_en.mp3",
                "accentId": findAccentId.uuid,
                "voiceId": voiceId,
                "gender": gender,
                "sources": "elevenlabs",
                "imageUrl": url || "Josh_m_TxGEqnHWrfWFTfGW9XjX_en.jpg"
            }
            await addVoiceInV1(v1Payload);

            const response = await httpClient.put(config.API_URL + '/admin/dashboard/companies/' + itemData._id + "/add_voice", payloadData);
            const { data, status } = response.data;
            if (status == 200 || status === 201) {
                toast.success("Company Update successfully")
                closeModal(1);
            } else {
                closeModal(0);
                toast.error("Something wrong!. Try again after sometime")
            }
        } catch (error) {
            const responseData = error.response;
            closeModal(0);
            if (responseData.status === 409) {
                toast.error("Email already present in system");
            } else {
                toast.error("Something wrong! Try gain later")
            }
        }
    }

    const addVoiceInV1 = async (payload) => {
        try {
            const response = await httpClientV1.post(config.V1_VIDEO_API_URL + '/voice', payload);
            return "ok"

        } catch (error) {
            console.log(error);
        }

    }



    const closeModal = (value) => {
        onHide(value);
    }

    return (

        <Modal {...props} size="lg" backdrop="static" onShow={getAllLangues}>

            <Modal.Body>

                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Voice Name
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="dubbing_limit"
                                value={voiceName}
                                onChange={(v) => setVoiceName(v.target.value)}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Voice Id
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={voiceId}
                                onChange={(v) => setVoiceId(v.target.value)}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <label>Accent</label>
                            <Form.Select
                                placeholder="Select accent"
                                type="text"
                                name="company"
                                className="form-control"
                                value={accent}
                                onChange={(v) => setAccent(v.target.value)}
                            >

                                <option key="-1" value="">Select Accent</option>
                                {config.LANGUAGES_ASSENTS.map((v, index) => {
                                    return (<option key={index} value={v.value}>{v.text}</option>)
                                })}

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label>Gender</label>
                            <Form.Select
                                placeholder="Select gender"
                                type="text"
                                name="company"
                                className="form-control"
                                value={gender}
                                onChange={(v) => setGender(v.target.value)}
                            >
                                <option key="-1" value="">Select Gender</option>
                                <option key="-1" value="male">Male</option>
                                <option key="-1" value="female">Female</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Url
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="file_size"
                                value={url}
                                onChange={(v) => setUrl(v.target.value)}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                <div className="clearfix"></div>
            </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => updateCompanyDetails()} >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default AddVoiceCompanyModal;