import React, { useState } from 'react';
import {
    Modal,
    Button,
    Col,
    Form,
    Row
} from "react-bootstrap";
import config from "constants/config"
import httpClient from 'http-common'
import toast from "react-hot-toast"

function SendMail(props) {
    const { onHide, itemData } = props

    const [selectValue, setSelectedValue] = useState("0");
    const closeModal = (value) => {
        onHide(value);
    }

    const sendMailToUser = async () => {
        try {
            await httpClient.post(config.API_URL + `/admin/dashboard/lead_video/${itemData._id}/email`, {type: selectValue});
            closeModal(1);
            toast.success("Mail send successfully!")

        } catch (error) {
            closeModal(1);
            toast.error("Something wrong try again")
        }

    }

    return (

        <Modal {...props} backdrop="static">
            <Modal.Header>
                <Modal.Title>Send Lead Mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col >

                        <Form.Select  value={selectValue} onChange={(v) => {
                            setSelectedValue(v.target.value);
                        }} aria-label="Default select templates" >
                            <option value="0">Select Original Lang </option>
                            <option value="1">Relevant </option>
                            <option value="2">Irrelevant</option>
                            <option value="3">Awaiting</option>
                        </Form.Select>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="success" onClick={() => {
                    if(selectValue === "0"){
                        toast.error("Please select template")
                    }else {
                        sendMailToUser()
                    }

                } } >
                    Send
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default SendMail;