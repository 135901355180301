import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import config from "constants/config"
import toast from "react-hot-toast"
import httpClient from 'http-common'

// react-bootstrap components
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";

function AddCompanyModal(props) {
    const {itemData} = props
    const [item, setItem] = useState(props.itemData);
    const [initialValue, setInitialValue] = useState(
        {
            email: "",
            name: "",
            tax_status: "",
            trialPeriod: 30,
            trialHours: 1,
            trialClips: 12
        }
    );

    const closeModal = (value) => {
        const {setSelectItemData, onHide} = props
        setSelectItemData(null)
        onHide(value);
    }
    useEffect(() => {
        console.log("itemData :::: ", itemData);
        if(item != null){
            setInitialValue({ email: (item.email != undefined && item.email != "") ? item.email : "",
            name: (item.name != undefined && item.name != "") ? item.name : "",
            tax_status: (item.tax_status != undefined && item.tax_status != "") ? item.tax_status : "",
            trialPeriod: 30,
            trialHours: 1,
            trialClips: 12
         })
        }
        
    }, [item]);


    return (
        <Modal {...props} size="lg" backdrop="static">
            <Formik
                initialValues={
                initialValue
            }
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const token = localStorage.getItem('token');
                        const headerData = {
                            'Authorization': `Bearer ${token}`
                        }
                        const response = await httpClient.post(config.API_URL +'/admin/companies', values);
                        const {data, status} = response.data;
                        if(status == 200 || status === 201){
                            toast.success("Company created successfully")
                            closeModal(1);
                          }else {
                            closeModal(0);
                            toast.error("Something wrong!. Try again after sometime")
                          }
                    } catch (error) {
                        const responseData = error.response;
                        closeModal(0);
                        if(responseData.status === 409){
                            toast.error("Email already present in system");
                        }else {
                            toast.error("Something wrong! Try gain later")
                        }
                    }

                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required("Required"),
                    name: Yup.string()
                        .required("Required"),
                    tax_status: Yup.string().required("Required"),
                    trialPeriod: Yup.number()
                        .required("Trial Period required!"),
                    trialHours: Yup.number()
                        .required("Trial Hours required!"),
                    trialClips: Yup.number()
                        .required("Trial Clips required!")
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>

                                <Modal.Header>
                                    <Modal.Title>Add Company</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <label htmlFor="exampleInputName">
                                                    Name
                                                </label>
                                                <Form.Control
                                                    placeholder="Name"
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.name && touched.name && "error"}
                                                ></Form.Control>
                                                {errors.name && touched.name && (
                                                    <div className="input-feedback">{errors.email}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    Email
                                                </label>
                                                <Form.Control
                                                    placeholder="Email"
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.email && touched.email && "error"}
                                                ></Form.Control>
                                                {errors.email && touched.email && (
                                                    <div className="input-feedback">{errors.email}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col >
                                            <Form.Group>
                                                <label>Tax Status</label>
                                                <Form.Select
                                                    placeholder="Select Tax Status"
                                                    type="text"
                                                    name="tax_status"
                                                    value={values.tax_status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={(errors.tax_status && touched.tax_status) ? "error form-control" : "form-control"}
                                                >
                                                    <option key="-1" value="">Select tax_status</option>
                                                    <option key="0" value="TAXABLE">TAXABLE</option>
                                                    <option key="1" value="EXEMPT">EXEMPT</option>

                                                    

                                                </Form.Select>
                                                {errors.company && touched.company && (
                                                    <div className="input-feedback">{errors.company}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col >
                                            <Form.Group>
                                                <label>Trial Period</label>
                                                <Form.Control
                                                    placeholder="Trial Period"
                                                    type="number"
                                                    name="trialPeriod"
                                                    value={values.trialPeriod}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.trialPeriod && touched.trialPeriod && "error"}
                                                ></Form.Control>
                                                {errors.trialPeriod && touched.trialPeriod && (
                                                    <div className="input-feedback">{errors.trialPeriod}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group>
                                                <label>Trial Hours</label>
                                                <Form.Control
                                                    placeholder="Trial Hours"
                                                    type="number"
                                                    name="trialHours"
                                                    value={values.trialHours}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.trialHours && touched.trialHours && "error"}
                                                ></Form.Control>
                                                {errors.trialHours && touched.trialHours && (
                                                    <div className="input-feedback">{errors.trialHours}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col >
                                            <Form.Group>
                                                <label>Trial Clips</label>
                                                <Form.Control
                                                    placeholder="Trial Clips"
                                                    type="number"
                                                    name="trialClips"
                                                    value={values.trialClips}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.trialClips && touched.trialClips && "error"}
                                                ></Form.Control>
                                                {errors.trialClips && touched.trialClips && (
                                                    <div className="input-feedback">{errors.trialClips}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="clearfix"></div>



                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>

                        </>
                    );
                }}

            </Formik>
        </Modal>
    );
}

export default AddCompanyModal;