import React, { useState, useEffect } from "react";
import httpClient from 'http-common'
import * as _ from "lodash"
import toast from "react-hot-toast"
import config from "constants/config"
import PublicVideoModal from "components/Shared/PublicVideoModal"
// react-bootstrap components
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  ListGroup
} from "react-bootstrap";
import ConfirmModal from "components/Shared/ConfirmModal"
import OverlayComponent from "components/Overlay/Overlay";

function PublicVideos() {
  const [publicVideos, setPublicVideos] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [message, setMessage] = useState('');
  const [selectItem, setSelectItem] = useState(null);

  const [isRatingActive, setIsRatingActive] = useState(false);


  const [modalConfirmShow, setModalConfirmShow] = useState(false);

  const getPublicVideos = async () => {
    const token = localStorage.getItem('token')
    try {
      const getPublicVideoData = await httpClient.get(config.API_URL + "/admin/dashboard/public_videos")
      setPublicVideos(getPublicVideoData.data.data)

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    getPublicVideos();
  }, []);

  const findLanguage = (v) => {
    const findLang = _.find(config.LANGUAGES_ASSENTS, (vt) => vt.value === v);
    return findLang.text;
  }
  return (
    <>
      <Container fluid>
        <Row >
          <Col md="12">
            <Card className="strpied-tabled-with-hover table-c">
              <Card.Header>
                <Row className="table-header-top">
                  <Card.Title as="h4" className="card_title">Public Video Lists</Card.Title>
                  <Button variant="primary" className="cart_title_button" onClick={() => {
                    setModalShow(true);
                  }} >Add Video</Button>

                </Row>

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">No</th>
                      <th className="border-0">Title</th>
                      <th className="border-0">Source Language</th>
                      <th className="border-0">Target Languages</th>
                      <th className="border-0">Industry</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Rating Status</th>

                      <th className="border-0">Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    {publicVideos.length > 0 && publicVideos.map((v, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{v.video_title}</td>
                          <td>
                            <OverlayComponent
                              flip={true}
                              _id={v._id}
                              trigger={["click"]}
                              popoverHeader={`Source Language - ${findLanguage(v.original_language)}`}
                              popoverBody={
                                <ListGroup variant="flush">
                                  <ListGroup.Item><span style={{ fontWeight: "bold" }}>Original Video Url:</span> {v.original_video_url}</ListGroup.Item>
                                  <ListGroup.Item><span style={{ fontWeight: "bold" }}>Thumnbnail Url:</span> {v.thumbnail_url}</ListGroup.Item>
                                </ListGroup>
                              }
                              placement="bottom"
                            >
                              <span style={{ borderBottom: '1px solid black', cursor: 'pointer' }}>{findLanguage(v.original_language)}</span>
                            </OverlayComponent>
                          </td>
                          <td>
                            <ul style={{ listStyleType: 'none' }}>
                              {v.multiple_lang.map((lang, index) => (
                                <li key={`${index}_${lang._id}`}>
                                  <OverlayComponent
                                    flip={true}
                                    _id={lang._id}
                                    trigger={["click"]}
                                    popoverHeader={`Target Language - ${findLanguage(lang.target_language)}`}
                                    popoverBody={
                                      <ListGroup variant="flush">
                                        <ListGroup.Item><span style={{ fontWeight: "bold" }}>Video Title:</span> {lang.video_title}</ListGroup.Item>
                                        <ListGroup.Item><span style={{ fontWeight: "bold" }}>Target Video Url:</span> {lang.dubbed_video_url}</ListGroup.Item>
                                        <ListGroup.Item><span style={{ fontWeight: "bold" }}>Thumnbnail Url:</span> {lang.thumbnail_url}</ListGroup.Item>
                                        <ListGroup.Item><span style={{ fontWeight: "bold" }}>Quality Checklist Url:</span> {lang.checklist_download_link}</ListGroup.Item>
                                      </ListGroup>
                                    }
                                    placement="bottom"
                                  >
                                    <span style={{ borderBottom: '1px solid black', cursor: 'pointer' }}>{findLanguage(lang.target_language)}</span>
                                  </OverlayComponent>
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>{v.industry[0].name}</td>
                          <td>{v.isActive ? "Enabled" : "Disabled"}</td>
                          <td>{v.is_rating_enabled ? "Enabled" : "Disabled"}</td>

                          <td>
                            <Dropdown>
                              <Dropdown.Toggle variant="info" id="dropdown-basic">
                                Action
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                  const valueData = !v.isActive ? "Enabled" : "Disabled";
                                  setSelectItem(v);
                                  setIsRatingActive(false);
                                  setMessage("Are you sure want to " + valueData)
                                  setModalConfirmShow(true)
                                }}>
                                 Active:- {v.isActive ? "Disabled" : "Enabled"}
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => {
                                  v.is_rating_enabled = !v.is_rating_enabled ? true : false;
                                  setSelectItem(v);
                                  setIsRatingActive(true);
                                  setMessage(`Are you sure want to ${v.is_rating_enabled ? "Enabled" : "Disabled"}`)
                                  setModalConfirmShow(true)
                                }}>
                                  Rating:- {v.is_rating_enabled ? "Disabled" : "Enabled"}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  setSelectItem({ ...v, isVideoEditing: true });
                                  setModalShow(true)
                                }}
                                >
                                  Edit Video
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {modalShow &&
        <PublicVideoModal selectItem={selectItem} show={modalShow} onHide={(number) => {
          if (number === 1) {
            getPublicVideos();
            setModalShow(false)
            setSelectItem(null)
          } else {
            setSelectItem(null)
            setModalShow(false)
          }
        }} ></PublicVideoModal>
      }

      <ConfirmModal message={message} setMessage={setMessage} show={modalConfirmShow} onHide={async (number) => {
        if (number === 1) {
          try {
            let payload = {}

            if(isRatingActive){
              payload.is_rating_enabled = selectItem.is_rating_enabled;
            }else {
              const valueData = !selectItem.isActive ? true : false;
              payload.isActive = valueData;
            }
            await httpClient.patch(config.API_URL + "/admin/dashboard/public_video/" + selectItem._id, payload)
            setSelectItem(null);
            getPublicVideos();
            setModalConfirmShow(false)
            toast.success("Update successfully")
          } catch (error) {
            setModalConfirmShow(false)
            toast.error(error.response.data)
          }
        } else {
          setModalConfirmShow(false)
        }
      }}
      ></ConfirmModal>
    </>
  );


}

export default PublicVideos;