import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import httpClient from 'http-common'
import config from "constants/config"

// react-bootstrap components
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function Dashboard() {
  let history = useHistory();
  const [dashboardDetails, setDashboardDetails] = useState(null);


  const getDashboardDetails = async () => {
    const token = localStorage.getItem('token')
    try {
      const getCompany = await httpClient.post(config.API_URL + "/admin/dashboard/details")
      setDashboardDetails(getCompany.data.data)

    } catch (error) {
      console.log(error)
    }

  }


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === undefined && token === null) {
      history.push('/login')
    }
    getDashboardDetails();
  }, []);

  return (
    <>
      <Container fluid>
       { dashboardDetails && <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-badge text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of Leads</p>
                      <Card.Title as="h4">{dashboardDetails.number_of_leads}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of Subscribers</p>
                      <Card.Title as="h4">{dashboardDetails.number_of_subscribers}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>

            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-button-play text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of dubbed(hr)</p>
                      <Card.Title as="h4">{dashboardDetails.total_dubbed_hours}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>

            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-button-play text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. of Videos</p>
                      <Card.Title as="h4">{dashboardDetails.total_dubbed_videos}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>

            </Card>
          </Col>
        </Row>}

        {dashboardDetails && <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-paper-2 text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of Video Request to be reviewed</p>
                      <Card.Title as="h4">{dashboardDetails.number_of_video_request_reviewed}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-cloud-download-93 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of Video pending for download</p>
                      <Card.Title as="h4">{dashboardDetails.number_of_pending_video_download}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>

            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-cloud-download-93 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">No. Of video downloaded </p>
                      <Card.Title as="h4">{dashboardDetails.number_of_video_download}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>

            </Card>
          </Col>

        </Row>}

      </Container>
    </>
  );
}

export default Dashboard;
