import axios from "axios";

const httpClientV1 = axios.create({
    baseURL: process.env.BASEURL,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': '47d64e2e-e96e-11ed-a05b-0242ac120003'
    },
    // baseURL: process.env.APP_API_BASE_URL,
});


httpClientV1.interceptors.request.use(function (config) {
    const token = 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJ1SWQiOiI1YTNlMmNmNS1mNmYzLTRiYzMtYjNlOC0yYTg5YTFiYzdlOTQiLCJ1UiI6IiIsImNJZCI6Im51bGwiLCJiUGFzcyI6dHJ1ZSwiaWF0IjoxNzE2MzU3OTg2LCJleHAiOjE3NDc4OTM5ODZ9.DcdANAD7_8NnYXJeCTe7avEQ4HQ_VuyRAUgal1mwHI0enaPZGr4NCbWbnM5v87WV';
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export default httpClientV1;