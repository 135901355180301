import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "constants/config"
import AddCompanyModal from "components/Shared/AddCompany"
import EditCompanyDetailsModal from "components/Shared/EditCompanyDetails"
import AddVoiceCompanyModal from "components/Shared/AddVoiceCompanyModal"
import ListOfVoice from "components/Shared/ListOfVoices"
import { useLocation, useHistory } from "react-router-dom";
import httpClient from 'http-common'

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Form,
    Dropdown,
    Col,
} from "react-bootstrap";

function Companies() {
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [allCompany, setAllCompany] = useState([]);

    const [modalShowEditSome, setModalShowEditSome] = useState(false);
    const [selectItemDataForLimit, setSelectItemDataForLimit] = useState(null);
    const [modalShowAddVoice, setModalShowAddVoice] = useState(false);
    const [selectItemDataForAddVoice, setSelectItemDataForAddVoice] = useState(null);
    const [selectItemData, setSelectItemData] = useState(null);

    const [selectItemDataForVoice, setSelectItemDataForVoice] = useState(null);
    const [modalShowListVoice, setModalShowListVoice] = useState(false);


    const [company, setCompany] = useState([]);
    let history = useHistory();

    const findObjectsByKeyLike = (arr, key, substring) => {
        const lowerCaseSubstring = substring.toLowerCase();
        return arr.filter(item => item[key].toLowerCase().includes(lowerCaseSubstring));
      }
      
    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h === 1 ? "H " : "H ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? "M " : "M ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? "S " : "S") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const getAllCompany = async () => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.get(config.API_URL + "/admin/dashboard/companies")
            setCompany(getCompany.data.data)
            setAllCompany(getCompany.data.data)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === undefined && token === null) {
            history.push('/login')
        }
        getAllCompany()
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">

                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Companies list</Card.Title>
                                  

                                </Row>


                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Row style={{ padding: "2rem" }}>

                                    <Form.Control type="text" placeholder="Search by name" value={searchValue} onChange={(v) => { 
                                        setSearchValue(v.target.value) 
                                        setTimeout(() => {
                                            const valueData = findObjectsByKeyLike(allCompany, 'name', v.target.value);
                                            setCompany(valueData)
                                            
                                        }, 1000);

                                        }} />

                                </Row>
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">ID</th>
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Email</th>
                                            <th className="border-0">Dubbing Limit</th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0">Total Credit</th>
                                            <th className="border-0">Price</th>

                                            {/* <th className="border-0">Left Credit</th> */}
                                            <th className="border-0">Action</th>
                                            {/* <th className="border-0">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            company.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.dubbingLimits}</td>
                                                        <td>{item.onboardingStage}</td>
                                                        <td>{item.total_credit}</td>
                                                        {item.price != undefined && <td>{item.price}</td>}
                                                        {item.price === undefined && <td>0</td>}

                                                        {/* <td>{item.left_credit}</td> */}

                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                    Action
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => {
                                                                        setSelectItemDataForLimit(item)
                                                                        setModalShowEditSome(true);

                                                                    }}>Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => {
                                                                        setSelectItemDataForAddVoice(item)
                                                                        setModalShowAddVoice(true)

                                                                    }}>Add Voice</Dropdown.Item>

                                                                    <Dropdown.Item onClick={() => {
                                                                        setSelectItemDataForVoice(item)
                                                                        setModalShowListVoice(true)

                                                                    }}>List Of Voices</Dropdown.Item>

                                                                </Dropdown.Menu>


                                                            </Dropdown>

                                                        </td>

                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {selectItemData != null && <AddCompanyModal setSelectItemData={setSelectItemData} itemData={selectItemData} show={modalShowEdit} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setModalShowEdit(false)
                } else {
                    setModalShowEdit(false)
                }
            }} />}
            {selectItemData === null && <AddCompanyModal setSelectItemData={setSelectItemData} itemData={selectItemData} show={modalShow} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setModalShow(false)
                } else {
                    setModalShow(false)
                }
            }} />}

            {selectItemDataForLimit != null && <EditCompanyDetailsModal itemData={selectItemDataForLimit} show={modalShowEditSome} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setSelectItemDataForLimit(null);
                    setModalShowEditSome(false)
                } else {
                    setSelectItemDataForLimit(null);
                    setModalShowEditSome(false)
                }
            }}></EditCompanyDetailsModal >}

            {selectItemDataForAddVoice != null && <AddVoiceCompanyModal itemData={selectItemDataForAddVoice} show={modalShowAddVoice} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setSelectItemDataForAddVoice(null);
                    setModalShowAddVoice(false)
                } else {
                    setSelectItemDataForAddVoice(null);
                    setModalShowAddVoice(false)
                }
            }}>

            </AddVoiceCompanyModal>}

            {selectItemDataForVoice != null && <ListOfVoice itemData={selectItemDataForVoice} show={modalShowListVoice} onHide={(number) => {
                if (number === 1) {
                    getAllCompany();
                    setSelectItemDataForVoice(null)
                    setModalShowListVoice(false)
                } else {
                    setSelectItemDataForVoice(null)
                    setModalShowListVoice(false)
                }
            }}>
            </ListOfVoice>}

        </>
    );
}

export default Companies;