import axios from "axios";
import { useHistory } from "react-router-dom";

const httpClient = axios.create({
  baseURL: process.env.BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '47d64e2e-e96e-11ed-a05b-0242ac120003'
  },
  // baseURL: process.env.APP_API_BASE_URL,
});

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});


httpClient.interceptors.response.use(
  res => res,
  err => {
    let history = useHistory();
    if (err) {
      history.push('/auth/login')
      return Promise.reject(err)
    }
  }
)


export default httpClient;