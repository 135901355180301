import React, { useState } from 'react';
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";
import httpClient from 'http-common'
import config from "constants/config"
import toast from "react-hot-toast"

function EditCompanyDetailsModal(props) {
    const { show, onHide, message, setMessage, itemData} = props


    const [dubbingLimits, setDubbinglimits] = useState(itemData.dubbingLimits);
    const [fileSize, setFileSize] = useState(itemData.file_size_limit || 500 );
    const [totalCredit, setTotalCredit] = useState(itemData.total_credit);
    const [price, setPrice] = useState(itemData.price || 0);




    const updateCompanyDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const headerData = {
                'Authorization': `Bearer ${token}`
            }
            const payloadData = {file_size_limit: fileSize, total_credit: totalCredit, dubbingLimits: dubbingLimits, price};
            if(payloadData.total_credit === itemData.total_credit){
               delete payloadData.total_credit;
            }
            const response = await httpClient.put(config.API_URL +'/admin/dashboard/companies/'+itemData._id, payloadData);
            const {data, status} = response.data;
            if(status == 200 || status === 201){
                toast.success("Company Update successfully")
                closeModal(1);
              }else {
                closeModal(0);
                toast.error("Something wrong!. Try again after sometime")
              }
        } catch (error) {
            const responseData = error.response;
            closeModal(0);
            toast.error("Something wrong! Try gain later")
        }
    }
   


    const closeModal = (value) => {
        onHide(value);
    }

    return (

        <Modal {...props} backdrop="static">
        
            <Modal.Body>

                    <Row>
                        <Col>
                            <Form.Group>
                                <label htmlFor="exampleInputName">
                                    Dubbing Limit
                                </label>
                                <Form.Control
                                    placeholder="Name"
                                    type="number"
                                    name="dubbing_limit"
                                    value={dubbingLimits}
                                    onChange={(v) => setDubbinglimits(v.target.value)}

                                ></Form.Control>

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label htmlFor="exampleInputName">
                                    File Size
                                </label>
                                <Form.Control
                                    placeholder="Name"
                                    type="number"
                                    name="file_size"
                                    value={fileSize}
                                    onChange={(v) => setFileSize(v.target.value)}
                                ></Form.Control>

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label htmlFor="exampleInputName">
                                    Total Credit
                                </label>
                                <Form.Control
                                    placeholder="Name"
                                    type="number"
                                    name="total_credit"
                                    value={totalCredit}
                                    onChange={(v) => setTotalCredit(v.target.value)}

                                ></Form.Control>

                            </Form.Group>
                        </Col>

                        
                    </Row>
                    <Row>
                    <Col>
                            <Form.Group>
                                <label htmlFor="exampleInputName">
                                    Price
                                </label>
                                <Form.Control
                                    placeholder="Name"
                                    type="number"
                                    name="total_credit"
                                    value={price}
                                    onChange={(v) => setPrice(v.target.value)}

                                ></Form.Control>

                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => updateCompanyDetails()} >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default EditCompanyDetailsModal;