import React, { useState, useEffect } from "react";
import config from "constants/config"
import { useHistory } from "react-router-dom";
import httpClient from 'http-common'
import * as _ from "lodash"
import { useDebouncedCallback } from 'use-debounce';
import SendMail from 'components/Shared/SendMail'

// react-bootstrap components
import {
    Card,
    Table,
    Container,
    Row,
    Col,
    Form,
    Dropdown,
} from "react-bootstrap";

function Leads() {
    const [leads, setLeads] = useState([])
    const [targetLang, setTargetLang] = useState(config.LANGUAGES_ASSENTS);
    const [sourceLang, setSourceLang] = useState(config.LANGUAGES_ASSENTS_SOURCE);
    const [selectedOriginal, setSelectedOriginal] = useState('');
    const [selectedTarget, setSelectedTarget] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [selectItem, setSelectItem] = useState('');


    let history = useHistory();
    let timeout = 0;



    const getAllLeads = async (dataValue) => {
        const token = localStorage.getItem('token')
        try {
            const queryData = {};
            if (selectedOriginal !== "" || (dataValue.original_language != undefined && dataValue.original_language !== "")) {
                queryData.original_language = (dataValue !== undefined && dataValue.original_language !== undefined && dataValue.original_language !== "") ? dataValue.original_language : selectedOriginal;
            }
            if (selectedTarget !== "" || (dataValue.target_language != undefined && dataValue.target_language !== "")) {
                queryData.target_language = (dataValue !== undefined && dataValue.target_language !== undefined && dataValue.target_language !== "") ? dataValue.target_language : selectedTarget;
            }
            if (searchValue !== "" || (dataValue.email != undefined && dataValue.email !== "")) {
                queryData.email = (dataValue !== undefined && dataValue.email !== undefined && dataValue.email !== "") ? dataValue.email : searchValue;
            }
            const getCompany = await httpClient.post(config.API_URL + "/admin/dashboard/lead_videos", queryData)
            setLeads(getCompany.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const findLanguage = (v) => {
        const findLang = _.find(config.LANGUAGES_ASSENTS, (vt) => vt.value === v);
        if (!findLang) {
            return ''
        } else {
            return findLang.text;

        }
    }



    const debounced = useDebouncedCallback(
        // function
        (value) => {
            getAllLeads({ email: value });
        },
        // delay in ms
        1000
    );




    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === undefined && token === null) {
            history.push('/login')
        }
        getAllLeads({ original_language: "", target_language: "", email: "" });
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">
                            <Card.Header>
                                <Row className="table-header-top">
                                    <Col xs={8}>
                                        <Card.Title as="h4" className="card_title">Leads list</Card.Title>
                                    </Col>
                                    <Col xs={2}>

                                        <Form.Select className="cart_title_button" aria-label="Default select example" value={selectedOriginal} onChange={async (v) => {
                                            setSelectedOriginal(v.target.value);
                                            getAllLeads({ original_language: v.target.value });

                                        }}>
                                            <option value="">Select Original Lang </option>
                                            {sourceLang.map((v) => {
                                                return <option value={v.value}>{v.text}</option>
                                            })}

                                        </Form.Select>
                                    </Col>
                                    <Col xs={2}>

                                        <Form.Select className="cart_title_button" aria-label="Default select example" value={selectedTarget} onChange={async (v) => {
                                            setSelectedTarget(v.target.value);
                                            getAllLeads({ target_language: v.target.value });

                                        }}>
                                            <option value="">Select Target Lang</option>
                                            {targetLang.map((v) => {
                                                return <option value={v.value}>{v.text}</option>
                                            })}

                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col xs={8}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control value={searchValue} type="text" placeholder="search by email" onChange={async (v) => {
                                                setSearchValue(v.target.value);
                                                debounced(v.target.value)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            {/* <th className="border-0">ID</th> */}
                                            <th className="border-0">Email</th>
                                            <th className="border-0">Phone</th>
                                            <th className="border-0">Original Accent</th>
                                            <th className="border-0">Target Accent</th>
                                            <th className="border-0">Video URL</th>
                                            <th className="border-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads.length > 0 && leads.map((v, index) => {
                                            return (<tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{v.email}</td>
                                                <td>{v.country_code} - {v.phone}</td>
                                                <td>{findLanguage(v.original_language)}</td>
                                                <td>{findLanguage(v.target_language)}</td>
                                                <td><a href={v.original_video_url}>URL</a></td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                            Action
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                setSelectItem(v)
                                                                setModalShow(true)
                                                            }} >Sent Mail</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </td>
                                            </tr>)

                                        })}

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {modalShow && selectItem !== '' && <SendMail show={modalShow} itemData={selectItem} onHide={async (type, value) => {
                if (type === 1) {
                    setModalShow(false);
                } else {
                    setModalShow(false)
                }
            }}
            ></SendMail>}
        </>
    );
}

export default Leads;